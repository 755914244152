import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"

const ClubSettings = ({
                        validationType,
                        clubSettingsConfig,
                        setClubSettingsConfig,
                        allCountries,
                      }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [tempValues, setTempValues] = useState({})

  const isCountrySelected = (country) => {
    return validationType.values.countryCodes.split(", ").includes(country)
  }

  const countries = allCountries.filter((country) => isCountrySelected(country))

  const toggleModal = () => {
    const initializedValues = {}
    countries.forEach((country) => {
      if (!clubSettingsConfig[country]) {
        initializedValues[country] = {
          type: "none",
          subscription_name: "",
          version: "v1",
          placement: "coupon",
        }
      } else {
        initializedValues[country] = { ...clubSettingsConfig[country] }
      }
    })
    setTempValues(initializedValues)
    setModalOpen(!modalOpen)
  }

  const handleRadioChange = (country, value) => {
    setTempValues((prev) => ({
      ...prev,
      [country]: {
        ...prev[country],
        type: value,
        ...(value === "none"
          ? { subscription_name: "", version: "", placement: "" }
          : value === "placement"
            ? { version: "v1", placement: "coupon" }
            : value === "shipping"
              ? { subscription_name: "" }
              : {}),
      },
    }))
  }

  const handleInputChange = (country, key, value) => {
    setTempValues((prev) => ({
      ...prev,
      [country]: {
        ...prev[country],
        [key]: value,
      },
    }))
  }

  const handleSaveConfiguration = () => {
    const cleanedValues = {}

    Object.keys(tempValues).forEach((country) => {
      const { type, subscription_name, version, placement } = tempValues[country]
      cleanedValues[country] = {
        type,
        subscription_name,
        ...(type === "placement" ? { version, placement } : {}),
      }
    })

    setClubSettingsConfig(cleanedValues)
    setModalOpen(false)
    console.log(clubSettingsConfig)
  }

  return (
    <div>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-3">Club Configuration By Countries</CardTitle>
              <Button color="primary" onClick={toggleModal}>
                Configure
              </Button>
              <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                <ModalHeader toggle={toggleModal}>Club Settings</ModalHeader>
                <ModalBody>
                  {countries.map((country) => (
                    <Card key={country} className="mb-3">
                      <CardBody>
                        <CardTitle>{country} Configuration</CardTitle>
                        <Row>
                          <Col lg={12}>
                            <div className="d-flex">
                              <div className="form-check countries-checkbox">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`type-${country}`}
                                  value="none"
                                  checked={tempValues[country]?.type === "none"}
                                  onChange={(e) =>
                                    handleRadioChange(country, e.target.value)
                                  }
                                />
                                <label className="form-check-label">None</label>
                              </div>
                              <div className="form-check countries-checkbox">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`type-${country}`}
                                  value="shipping"
                                  checked={tempValues[country]?.type === "shipping"}
                                  onChange={(e) =>
                                    handleRadioChange(country, e.target.value)
                                  }
                                />
                                <label className="form-check-label">Shipping</label>
                              </div>
                              <div className="form-check countries-checkbox">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`type-${country}`}
                                  value="placement"
                                  checked={tempValues[country]?.type === "placement"}
                                  onChange={(e) =>
                                    handleRadioChange(country, e.target.value)
                                  }
                                />
                                <label className="form-check-label">Placement</label>
                              </div>
                            </div>
                          </Col>
                          {tempValues[country]?.type === "shipping" && (
                            <Col lg={12} className="mt-3">
                              <Label className="form-label">
                                Subscription Name
                              </Label>
                              <Input
                                type="text"
                                value={
                                  tempValues[country]?.subscription_name || ""
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    country,
                                    "subscription_name",
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                          )}
                          {tempValues[country]?.type === "placement" && (
                            <>
                              <Col lg={12} className="mt-3">
                                <Label className="form-label">
                                  Subscription Name
                                </Label>
                                <Input
                                  type="text"
                                  value={
                                    tempValues[country]?.subscription_name || ""
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      country,
                                      "subscription_name",
                                      e.target.value
                                    )
                                  }
                                />
                              </Col>
                              <Col lg={6} className="mt-3">
                                <Label className="form-label">Version</Label>
                                <select
                                  className="form-select"
                                  value={tempValues[country]?.version || "v1"}
                                  onChange={(e) =>
                                    handleInputChange(
                                      country,
                                      "version",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="v1">v1</option>
                                  <option value="v2">v2</option>
                                </select>
                              </Col>
                              <Col lg={6} className="mt-3">
                                <Label className="form-label">Placement</Label>
                                <select
                                  className="form-select"
                                  value={tempValues[country]?.placement || "coupon"}
                                  onChange={(e) =>
                                    handleInputChange(
                                      country,
                                      "placement",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="coupon">Coupon</option>
                                  <option value="billingaddress">
                                    Billing Address
                                  </option>
                                </select>
                              </Col>
                            </>
                          )}
                        </Row>
                      </CardBody>
                    </Card>
                  ))}
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      color="secondary"
                      className="me-2"
                      onClick={toggleModal}
                    >
                      Cancel
                    </Button>
                    <Button color="success" onClick={handleSaveConfiguration}>
                      Save Configurations
                    </Button>
                  </div>
                </ModalBody>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ClubSettings
