import React from "react"
import { Card, CardBody, CardTitle, Col, Input, Label, Row } from "reactstrap"

const ReviewConfig = ({ validationType, allCountries }) => {
  console.log(validationType.values.reviewConfig)

  const isCountrySelected = country => {
    return validationType.values.countryCodes.split(", ").includes(country)
  }

  if (Array.isArray(validationType.values.reviewConfig)) {
    validationType.values.reviewConfig =
      validationType.values.reviewConfig.reduce((acc, curr) => {
        acc[curr.country] = typeof curr.configJson === 'string' ? curr.configJson: JSON.stringify(curr.configJson)
        return acc
      }, {})
  } else {
    const newReviewConfig = {}
    for (const co of allCountries) {
      const isSelectedCountry = isCountrySelected(co)
      if (isSelectedCountry && validationType.values.reviewConfig && validationType.values.reviewConfig[co]) {
        newReviewConfig[co] = validationType.values.reviewConfig[co]
      }
    }
    validationType.values.reviewConfig = newReviewConfig
  }

  const handleConfigChange = (e, country) => {
    const newConfig = {
      ...validationType.values.reviewConfig,
      [country]: e.target.value,
    }

    validationType.setFieldValue("reviewConfig", newConfig)
  }

  return (
    <div>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-3">Review Configs</CardTitle>
              {allCountries.map((country, ind) => {
                const isSelectedCountry = isCountrySelected(country)
                if (isSelectedCountry) {
                  return (
                    <div key={ind} className="mb-3">
                      <Label className="form-label">
                        Review Config for {country}
                      </Label>
                      <Input
                        name={`reviewConfig.${country}`}
                        type="textarea"
                        rows="5"
                        onChange={e => handleConfigChange(e, country)}
                        onBlur={validationType.handleBlur}
                        value={
                          validationType.values.reviewConfig[country] || ""
                        }
                      />
                    </div>
                  )
                }
                return null
              })}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ReviewConfig
